
import Vue from "vue"
import CommentPlacement from "@evercam/shared/components/Comments/CommentPlacement"
import CommentMarker from "@evercam/shared/components/Comments/CommentMarker"
import { useTimelineCommentsGroupStore } from "@/stores/timeline/timelineCommentsGroup"
import { useTimelineStore } from "@/stores/timeline/timeline"
import { useRecordingsStore } from "@/stores/recordings"
import { useAccountStore } from "@/stores/account"
import { useLayoutStore } from "@/stores/layout"
import { mapStores } from "pinia"
import { AnalyticsEvent } from "@evercam/shared/types"

export default Vue.extend({
  name: "RecordingsPlayerCommentsOverlay",
  components: {
    CommentPlacement,
    CommentMarker,
  },
  props: {
    initialParams: {
      type: Object,
      default: () => ({
        height: 0,
        width: 0,
      }),
    },
  },
  data() {
    return {
      normalizedPointCoordinates: [0, 0],
      position: null,
    }
  },
  computed: {
    ...mapStores(
      useAccountStore,
      useTimelineCommentsGroupStore,
      useLayoutStore,
      useTimelineStore,
      useRecordingsStore
    ),
    mousePosition(): { x: number; y: number } {
      return {
        x: this.initialParams?.imageMouse?.x,
        y: this.initialParams?.imageMouse?.y,
      }
    },
  },
  watch: {
    initialParams(newParams, oldParams) {
      if (
        this.position &&
        (newParams.offsetHeight !== oldParams.offsetHeight ||
          newParams.offsetWidth !== oldParams.offsetWidth)
      ) {
        this.position = {
          x:
            this.mousePosition.x *
            (newParams.offsetWidth / oldParams.offsetWidth),
          y:
            this.mousePosition.y *
            (newParams.offsetHeight / oldParams.offsetHeight),
        }
      }
    },
  },
  methods: {
    handlePointSelection(newPoint) {
      if (this.timelineCommentsGroupStore.isAnnotationActive) {
        const timestamp =
          this.timelineStore.timestamp || this.recordingsStore.selectedTimestamp
        this.position = newPoint
        this.normalizedPointCoordinates = [
          newPoint.x / this.initialParams.offsetWidth,
          newPoint.y / this.initialParams.offsetHeight,
        ]
        this.timelineCommentsGroupStore.newCommentData = {
          position2d: this.normalizedPointCoordinates,
          timestamp: this.$moment(timestamp).toISOString(),
          projectExid: this.timelineStore.selectedProject.exid,
          cameraExid: this.timelineStore.cameraExid,
          content: "",
        }
        this.$analytics.saveEvent(AnalyticsEvent.commentsPlaceMarker)
      }
    },
    addComment(comment) {
      this.timelineCommentsGroupStore.addComment(
        this.timelineStore.selectedProject.exid,
        {
          ...this.timelineCommentsGroupStore.newCommentData,
          content: comment.content,
        }
      )
      this.$analytics.saveEvent(AnalyticsEvent.commentsSave, {
        text: comment.content,
      })
      this.timelineCommentsGroupStore.allowComment(false)
      this.timelineCommentsGroupStore.newCommentData = null
      this.timelineCommentsGroupStore.changeCommentsVisibility(true)
      this.layoutStore.enableRightSidebar()
      this.position = null
    },
    closeCommentDialog() {
      this.timelineCommentsGroupStore.allowComment(false)
      this.timelineCommentsGroupStore.newCommentData = null
      this.position = null
      this.$analytics.saveEvent(AnalyticsEvent.commentsCancelSave)
    },
  },
})
