
import Vue, { PropType } from "vue"
import { TimelineMarker } from "@evercam/ui"
import { AnalyticsEvent, ProjectSmartMilestone } from "@evercam/shared/types"
import { getAiApiUrl } from "@evercam/shared/api/aiApi"
import { toQueryString } from "@evercam/shared/utils"

export default Vue.extend({
  name: "TheTimelineSidebarMilestones",
  props: {
    token: {
      type: String,
      required: true,
    },
    milestones: {
      type: Array as PropType<ProjectSmartMilestone[]>,
      required: true,
    },
    activeMilestone: {
      type: [Object, undefined] as PropType<TimelineMarker | undefined>,
      default: undefined,
    },
  },
  data() {
    return {
      isSearchActive: false,
      globalSearchResults: [],
    }
  },
  computed: {
    filteredMilestones(): ProjectSmartMilestone[] {
      if (!this.isSearchActive) {
        return this.milestones
      }

      return this.globalSearchResults.reduce((acc, match) => {
        return [...acc, this.milestones[match.index]]
      }, [])
    },
    searchItems(): (string | number | undefined)[][] {
      return this.milestones.map((milestone) =>
        [
          milestone?.response?.title,
          ...(milestone?.response?.changes || []),
        ].flat()
      )
    },
  },
  methods: {
    getSmartMilestoneThumbnail(milestone: ProjectSmartMilestone) {
      let url = ""
      if (!milestone.config?.toSnapshot) {
        url = this.getSnapshotDefaultSrc(milestone.cameraExid, milestone.toDate)
      } else {
        url = `${getAiApiUrl()}/projects/smart_milestones/thumbnail?link=${milestone.config.toSnapshot.replace(
          "average.jpeg",
          ""
        )}&authorization=${this.token}`
      }

      return this.$imgproxy.get360pResizedImageUrl(url, false)
    },
    getSnapshotDefaultSrc(cameraExid, createdAt) {
      const queryParams = {
        authorization: this.token,
        view: true,
      }

      return `${
        this.$config.public.apiURL
      }/cameras/${cameraExid}/recordings/snapshots/${createdAt}?${toQueryString(
        queryParams
      )}`
    },

    onGlobalSearchResultsChange(results) {
      this.isSearchActive = true
      this.globalSearchResults = results
    },
    onGLobalSearchResultsReset() {
      this.isSearchActive = false
    },
    onSearch(input) {
      if (input?.target?.value?.length >= 3) {
        this.$analytics.saveEvent(AnalyticsEvent.smartMilestonesSearch, {
          text: input.target.value,
        })
      }
    },
    toggleMilestoneDetails() {
      this.$analytics.saveEvent(AnalyticsEvent.smartMilestonesToggleDetails)
    },
  },
})
