
import Vue, { PropType } from "vue"
import { TimelineMarker } from "@evercam/ui"
import {
  Camera,
  Project,
  ProjectSmartMilestone,
  TimelineGroupId,
  Comment,
  User,
} from "@evercam/shared/types"
import TheTimelineSidebarMilestones from "@/components/timeline/TheTimelineSidebarMilestones"
import TheTimelineSidebarBimMilestones from "@/components/timeline/TheTimelineSidebarBimMilestones.vue"
import RecordingsXrayExportDialog from "@/components/recordings/RecordingsXrayExportDialog"
import CopilotChat from "@evercam/shared/components/copilot/CopilotChat.vue"
import TheCommentsList from "@evercam/shared/components/Comments/TheCommentsList"
import CompareExportDialog from "@/components/CompareExportDialog"

import { useTimelineStore } from "@/stores/timeline/timeline"
import { useTimelineCommentsGroupStore } from "@/stores/timeline/timelineCommentsGroup"
import { useRecordingsStore } from "@/stores/recordings"
import { useAccountStore } from "@/stores/account"
import { useLayoutStore } from "@/stores/layout"
import { useProjectStore } from "@/stores/project"
import { useCameraStore } from "@/stores/camera"
import { useCompareStore } from "@/stores/compare"
import { mapStores } from "pinia"

export default Vue.extend({
  name: "TheTimelineSidebar",
  components: {
    CopilotChat,
    TheTimelineSidebarMilestones,
    TheTimelineSidebarBimMilestones,
    RecordingsXrayExportDialog,
    TheCommentsList,
    CompareExportDialog,
  },
  props: {
    token: {
      type: String,
      required: true,
    },
    groupsVisibility: {
      type: Object as PropType<Record<TimelineGroupId, boolean>>,
      required: true,
    },
    activeMarker: {
      type: [Object, undefined] as PropType<TimelineMarker | undefined>,
      default: undefined,
    },
    smartMilestones: {
      type: Array as PropType<ProjectSmartMilestone[]>,
      default: () => [] as ProjectSmartMilestone[],
    },
    camera: {
      type: Object as PropType<Camera>,
      required: true,
    },
    project: {
      type: Object as PropType<Project>,
      required: true,
    },
    user: {
      type: Object as PropType<User>,
      required: true,
    },
    comments: {
      type: Array as PropType<Comment[]>,
      default: () => [] as Comment[],
    },
    timezone: {
      type: String,
      default: "Europe/Dublin",
    },
    showCompare: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapStores(
      useTimelineCommentsGroupStore,
      useAccountStore,
      useLayoutStore,
      useRecordingsStore,
      useTimelineStore,
      useProjectStore,
      useCameraStore,
      useCompareStore
    ),
    showSmartMilestones(): boolean {
      return this.groupsVisibility[TimelineGroupId.SmartMilestones]
    },
    showBimSettings(): boolean {
      return this.groupsVisibility[TimelineGroupId.Bim]
    },
    showComments(): boolean {
      return this.groupsVisibility[TimelineGroupId.Comments]
    },
    showXrayExportSettings(): boolean {
      return this.recordingsStore.isXrayActive
    },
  },
})
